import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import VisuallyHidden from "@reach/visually-hidden";
import posed, { PoseGroup } from "react-pose";
import styled from "styled-components";
import { Text } from "rebass";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import * as easings from "d3-ease";

import { PoseableButton } from "components/poseable";
import { normal } from "components/typography/sizes";
import GlobalEmitter from "utils/GlobalEmitter";
import events from "utils/events";

const Menu = styled(
  posed.div({
    exit: {
      beforeChildren: true,
      opacity: 0,
      transition: {
        duration: 200
      }
    },
    enter: {
      opacity: 1,
      staggerChildren: 25
    }
  })
)`
  position: absolute;
  display: block;
  top: 100%;
  width: 100%;
  z-index: 10;
  max-height: 400px;
  overflow: auto;
`;

const MainButton = styled(
  posed(PoseableButton)({
    hoverable: true,
    focusable: true,
    init: {
      color: ({ color }) => color
    },
    blur: {
      color: ({ color }) => color
    },
    hover: {
      color: ({ theme }) => theme.headingColor
    },
    focus: {
      color: ({ theme }) => theme.headingColor
    }
  })
)`
  width: 100%;
  background: transparent;
  border: none;
  text-align: left;
  font-family: Calibre;
  font-weight: bold;
  padding-left: 0;
  cursor: pointer;
`;

const MenuButton = styled(
  posed(PoseableButton)({
    hoverable: true,
    focusable: true,
    preEnter: {
      opacity: 0,
      y: -10
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        y: {
          type: "tween",
          ease: easings.easeSinOut,
          duration: 200
        }
      }
    },
    init: {
      color: ({ color }) => color,
      backgroundColor: ({ bg }) => bg
    },
    blur: {
      color: ({ color }) => color,
      backgroundColor: ({ bg }) => bg
    },
    hover: {
      color: ({ theme }) => theme.textColor,
      backgroundColor: ({ theme }) => theme.headingColor
    },
    focus: {
      color: ({ theme }) => theme.textColor,
      backgroundColor: ({ theme }) => theme.headingColor
    }
  })
)`
  text-align: left;
  font-family: Calibre;
  font-weight: bold;
  display: block;
  width: 100%;
`;

export default class Dropdown extends PureComponent {
  static ID = 0;

  static propTypes = {
    theme: PropTypes.object,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    ).isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    replaceDefaultWithLabel: PropTypes.bool
  };

  static defaultProps = {
    items: [],
    label: null,
    onChange: null,
    replaceDefaultWithLabel: false,
    style: {}
  };

  state = { id: null };

  componentDidMount() {
    Dropdown.ID++;
    this.setState({ id: `dropdown-${Dropdown.ID}` });
  }

  onMouseOver = () => {
    GlobalEmitter.emit(events.disableScroll);
  };
  onMouseOut = () => {
    GlobalEmitter.emit(events.enableScroll);
  };

  render() {
    const {
      theme,
      items,
      label,
      onChange,
      replaceDefaultWithLabel,
      style = {},
      ...rest
    } = this.props;
    const { id } = this.state;

    return (
      <Downshift
        itemToString={item => (item ? item.value : null)}
        getValue={item => (item ? item.value : null)}
        onChange={onChange}
        {...rest}
      >
        {({
          getLabelProps,
          getItemProps,
          isOpen,
          toggleMenu,
          selectedItem,
          highlightedIndex
        }) => (
          <div
            style={{ position: `relative`, display: `inline-block`, ...style }}
          >
            {label && (
              <VisuallyHidden>
                <Text
                  is="label"
                  fontFamily={"Calibre"}
                  fontWeight={"bold"}
                  fontSize={normal}
                  {...getLabelProps({
                    htmlFor: id
                  })}
                >
                  {label}
                </Text>
              </VisuallyHidden>
            )}
            <div
              style={{
                position: `relative`,
                display: `inline-block`,
                width: `100%`
              }}
            >
              <MainButton
                theme={theme}
                tabIndex={0}
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                onClick={toggleMenu}
                fontSize={normal}
                borderRadius={0}
                aria-expanded={isOpen}
                color={theme.colors.black}
                py={[10, 12, 14]}
                px={[12, 14, 18]}
                bg={"transparent"}
              >
                <span>
                  {replaceDefaultWithLabel
                    ? !selectedItem || selectedItem.value === items[0].value
                      ? label
                      : selectedItem.label
                    : selectedItem
                    ? selectedItem.label
                    : label}
                </span>
                <Text
                  className="icon"
                  is="i"
                  fontSize={[10, 15]}
                  ml={[10, 15]}
                  color={theme.headingColor}
                >
                  <FontAwesomeIcon
                    icon={isOpen ? faChevronUp : faChevronDown}
                  />
                </Text>
              </MainButton>
              <PoseGroup animateOnMount={true} preEnterPose={"preEnter"}>
                {isOpen ? (
                  <Menu
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}
                    onFocus={() => {}}
                    onBlur={() => {}}
                    id={id}
                    key={id} /*className={styles.menu}*/
                  >
                    {items.map((item, idx) => {
                      return (
                        <MenuButton
                          tabIndex={0}
                          {...getItemProps({ item })}
                          i={idx}
                          theme={theme}
                          key={item.value}
                          fontSize={normal}
                          borderRadius={0}
                          color={theme.colors.grey}
                          bg={theme.colors.black}
                          py={[10, 12, 14]}
                          px={[12, 14, 18]}
                          style={{
                            cursor:
                              selectedItem === item ? "default" : "pointer"
                          }}
                        >
                          <span>{item.label}</span>
                        </MenuButton>
                      );
                    })}
                  </Menu>
                ) : null}
              </PoseGroup>
            </div>
          </div>
        )}
      </Downshift>
    );
  }
}
