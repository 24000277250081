import * as easings from "d3-ease";

import { Container, PosedSection } from "components/layout";
import {
  PoseableColumn,
  PoseableHeading,
  PoseableRow,
} from "components/poseable";
import React, { PureComponent } from "react";
import { normal, smallHeading } from "components/typography/sizes";
import posed, { PoseGroup } from "react-pose";

import { Box } from "@rebass/grid";
import GatsbyLink from "gatsby-link";
import GlobalEmitter from "utils/GlobalEmitter";
import Img from "components/Img";
import PinchZoom from "components/PinchZoom";
import events from "utils/events";
import { fadeUp } from "components/poses";
import newlineToBr from "utils/newlineToBr";
import resolveLink from "utils/resolveLink";
import styled from "styled-components";

const PosedRow = posed(PoseableRow)({
  enter: {
    x: 0,
    beforeChildren: true,
    staggerChildren: 50,
    transition: {
      delay: 500,
      type: "tween",
      duration: 0,
    },
  },
  exit: {
    x: -1,
    afterChildren: true,
    staggerChildren: 10,
    staggerDirection: -1,
    transition: {
      type: "tween",
      duration: 0,
    },
  },
});

const PosedColumn = styled(
  posed(PoseableColumn)({
    enter: {
      opacity: 1,
      y: 0,

      transition: {
        type: "tween",
        ease: easings.easeSinOut,
        duration: 250,
      },
    },
    exit: {
      opacity: 0,
      y: 0,
      transition: {
        type: "tween",
        ease: easings.easeSinIn,
        duration: 200,
      },
    },
  })
)`
  & figure {
    max-width: 100%;

    & img {
      max-width: 100%;
      width: 100%;
      height: auto !important;
    }
  }
`;

const PosedHeading = posed(PoseableHeading)(fadeUp);

export default class CasesBlock extends PureComponent {
  state = { entered: false, enoughImagesLoaded: false };
  onChangeComplete = (e) => {
    const h = document.getElementById("cases-grid").getBoundingClientRect()
      .height;
    document.getElementById("cases-grid-container").style.height = `${h}px`;
    GlobalEmitter.emit(events.resize);
    GlobalEmitter.emit(events.resizeScrollbar);
  };

  componentDidMount() {
    this.imagesLoaded = 0;
    this.enterTimeout = setTimeout(() => {
      this.setState({ entered: true });
    }, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.enterTimeout);
  }

  imageLoaded = () => {
    this.imagesLoaded++;
    if (this.imagesLoaded === 2) {
      this.setState({ enoughImagesLoaded: true });
    }
  };

  render() {
    const { cases, filter, theme } = this.props;
    const { entered, enoughImagesLoaded } = this.state;

    return (
      <PosedSection
        id="cases"
        visible={entered}
        visibleCondition={enoughImagesLoaded}
        style={{ overflowX: `hidden` }}
      >
        <Container>
          <div
            id="cases-grid-container"
            style={{ width: `100%`, display: `block`, position: `relative` }}
          >
            <PoseGroup
              animateOnMount={false}
              flipMove={false}
              onRest={this.onChangeComplete}
            >
              <PosedRow id="cases-grid" key={`filtered-cases-${filter}`}>
                {cases.map(
                  (
                    { id, title, client, tagline, typeOfWork, thumbnail },
                    idx
                  ) => {
                    return (
                      <PosedColumn
                        key={`case-${idx}`}
                        width={[1, 1 / 2, 1 / 2]}
                        mb={[40, 60, 80, 100, 120]}
                      >
                        <PosedHeading
                          visibledelay={idx * 300}
                          fontFamily="Calibre"
                          fontWeight="bold"
                          fontSize={normal}
                          color={theme.headingColor}
                          mb={[18, 20, 22, 24]}
                        >
                          {typeOfWork.name}
                        </PosedHeading>
                        <GatsbyLink to={resolveLink(id)} title={title}>
                          <PinchZoom
                            revealColor={theme.revealColor}
                            visibledelay={idx * 300 + 50}
                            hoverable={true}
                            focusable={true}
                            mb={[25, 35, 40]}
                            initialPose={entered ? "visible" : "hidden"}
                          >
                            <Box
                              is={Img}
                              {...thumbnail}
                              alt={title}
                              onLoad={this.imageLoaded}
                            />
                          </PinchZoom>
                        </GatsbyLink>
                        <PosedHeading
                          visibledelay={idx * 300 + 100}
                          fontFamily="Calibre"
                          fontWeight="bold"
                          fontSize={normal}
                          color={theme.textColor}
                          mb={[10, 15]}
                        >
                          {client.name}
                        </PosedHeading>
                        <PosedHeading
                          visibledelay={idx * 300 + 100}
                          fontFamily="Larish"
                          fontWeight="semibold"
                          fontSize={smallHeading}
                          color={theme.textColor}
                          style={{ maxWidth: `75%` }}
                        >
                          {newlineToBr(tagline)}
                        </PosedHeading>
                      </PosedColumn>
                    );
                  }
                )}
              </PosedRow>
            </PoseGroup>
          </div>
        </Container>
      </PosedSection>
    );
  }
}
