import * as easings from "d3-ease";

import { Column, Container, PosedSection, Row } from "components/layout";
import {
  PoseableColumn,
  PoseableHeading,
  PoseableRow,
} from "components/poseable";
import React, { PureComponent } from "react";
import posed, { PoseGroup } from "react-pose";

import { Box } from "@rebass/grid";
import BreakpointListener from "components/BreakpointListener";
import Dropdown from "components/Dropdown";
import GlobalEmitter from "utils/GlobalEmitter";
import { Heading } from "rebass";
import Img from "components/Img";
import events from "utils/events";
import { fadeUp } from "components/poses";
import newlineToBr from "utils/newlineToBr";
import { smallHeading } from "components/typography/sizes";

const PosedRow = posed(PoseableRow)({
  enter: {
    x: 0,
    beforeChildren: true,
    staggerChildren: 20,
    transition: {
      delay: 500,
      type: "tween",
      duration: 0,
    },
  },
  exit: {
    x: -1,
    afterChildren: true,
    staggerChildren: 10,
    staggerDirection: -1,
    transition: {
      type: "tween",
      duration: 0,
    },
  },
});

const PosedColumn = posed(PoseableColumn)({
  ...fadeUp,
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      ease: easings.easeSinOut,
      duration: 250,
    },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: {
      type: "tween",
      ease: easings.easeSinIn,
      duration: 200,
    },
  },
});

const PosedHeading = posed(PoseableHeading)(fadeUp);

export default class ClientsBlock extends PureComponent {
  onIndustryChange = (e) => {
    this.setState({
      industry: e.value,
      clients: this.getFilteredClients(e.value, this.state.type),
    });
  };

  onTypeChange = (e) => {
    this.setState({
      type: e.value,
      clients: this.getFilteredClients(this.state.industry, e.value),
    });
  };

  getFilteredClients(industry = null, type = null) {
    if (industry === "All") {
      industry = null;
    }
    if (type === "All") {
      type = null;
    }

    if (!industry && !type) {
      return this.props.clients;
    } else {
      return this.props.clients.filter((c) => {
        const hasIndustry =
          !industry ||
          (c.industry && c.industry.map((i) => i.name).indexOf(industry) >= 0);
        const hasType =
          !type ||
          (c.typeOfWork && c.typeOfWork.map((t) => t.name).indexOf(type) >= 0);
        return hasIndustry && hasType;
      });
    }
  }

  constructor(props) {
    super(props);
    this.industries = [];
    this.types = [];

    props.clients.forEach((c) => {
      if (c.industry) {
        c.industry.forEach((i) => {
          if (this.industries.indexOf(i.name) === -1) {
            this.industries.push(i.name);
          }
        });
      }
      if (c.typeOfWork) {
        c.typeOfWork.forEach((t) => {
          if (this.types.indexOf(t.name) === -1) {
            this.types.push(t.name);
          }
        });
      }
    });

    this.industries.sort();

    this.industries = [{ label: "All", value: "All" }].concat(
      this.industries.map((i) => ({ label: i, value: i }))
    );

    this.types = [{ label: "All", value: "All" }].concat(
      this.types.map((t) => ({ label: t, value: t }))
    );

    this.state = {
      type: "All",
      industry: "All",
      clients: this.getFilteredClients("All", "All"),
      mobile: BreakpointListener.size < 2,
    };
  }

  onBreakpoint = ({ newSize }) => {
    this.setState({ mobile: newSize < 2 });
  };

  componentDidMount() {
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
  }

  componentWillUnmount() {
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
  }

  onChangeComplete = (e) => {
    const h = document.getElementById("client-grid").getBoundingClientRect()
      .height;
    document.getElementById("client-grid-container").style.height = `${h}px`;
    GlobalEmitter.emit(events.resize);
    GlobalEmitter.emit(events.resizeScrollbar);
  };

  render() {
    const { heading, theme, showFilters } = this.props;
    const { clients, industry, type, mobile } = this.state;
    return (
      <PosedSection id="clients" style={{ overflowX: `hidden` }}>
        <Container>
          <Row my={0} mb={[25, 35, 40]} mt={0}>
            <Column width={[1, 1, 1 / 2]}>
              <PosedHeading
                is="h3"
                color={theme.textColor}
                fontFamily="Larish"
                fontWeight="semibold"
                fontSize={smallHeading}
                lineHeight={1.43333333}
              >
                {newlineToBr(heading)}
              </PosedHeading>
            </Column>
            {showFilters && (
              <Column
                width={[1, 1, 1, 1 / 2]}
                mt={[1, 2, 3, 4, 5]}
                mb={[-1, -2, -3, -4, -5]}
                style={{
                  textAlign: "right",
                  display: `inline-flex`,
                  flex: `none`,
                  alignSelf: mobile ? `flex-start` : `flex-end`,
                  height: `100%`,
                  justifyContent: mobile ? `flex-start` : `flex-end`,
                }}
              >
                <Box mr={[18, 18, 18, 22, 24]}>
                  <Dropdown
                    theme={theme}
                    label={"Filter by industry"}
                    onChange={this.onIndustryChange}
                    items={this.industries}
                    style={{ width: mobile ? 160 : 225 }}
                  />
                </Box>
                <Box>
                  <Dropdown
                    theme={theme}
                    onChange={this.onTypeChange}
                    label={"Filter by type of work"}
                    items={this.types}
                    style={{ width: mobile ? 200 : 225 }}
                  />
                </Box>
              </Column>
            )}
          </Row>
          <div
            id="client-grid-container"
            style={{ width: `100%`, display: `block`, position: `relative` }}
          >
            <PoseGroup
              animateOnMount={false}
              flipMove={false}
              onRest={this.onChangeComplete}
            >
              <PosedRow id="client-grid" key={`filtered-${industry}-${type}`}>
                {clients.length > 0 ? (
                  clients.map(({ logo, name }, idx) => {
                    return (
                      <PosedColumn
                        key={`client-${idx}`}
                        width={[1 / 2, 1 / 2, 1 / 4]}
                        mb={[2, 3]}
                        visibledelay={200 + idx * 100}
                      >
                        <Img alt={name} {...logo} />
                      </PosedColumn>
                    );
                  })
                ) : (
                  <PosedColumn key={`none`} width={1} mb={[2, 3]}>
                    <Heading is="h5" fontSize={smallHeading} textAlign="center">
                      No clients found.
                    </Heading>
                  </PosedColumn>
                )}
              </PosedRow>
            </PoseGroup>
          </div>
        </Container>
      </PosedSection>
    );
  }
}
