import * as easings from "d3-ease";

import { Box } from "@rebass/grid";
import { PoseableBox } from "components/poseable";
import PropTypes from "prop-types";
import React from "react";
import posed from "react-pose";
import styled from "styled-components";

const zoomProps = {
  init: {
    scale: 1,
    beforeChildren: false,
    transition: {
      type: `tween`,
      duration: 200,
      ease: easings.easeSinIn,
    },
  },
  blur: {
    scale: 1,
    beforeChildren: false,
    transition: {
      type: `tween`,
      duration: 200,
      ease: easings.easeSinIn,
    },
  },
  hover: {
    scale: 0.9,
    beforeChildren: false,
    transition: {
      delay: 0,
      type: `tween`,
      duration: 300,
      ease: easings.easeSinOut,
    },
  },
  focus: {
    scale: 0.9,
    beforeChildren: false,
    transition: {
      delay: 0,
      type: `tween`,
      duration: 300,
      ease: easings.easeSinOut,
    },
  },
};

const Zoom = posed(PoseableBox)(zoomProps);
const HoverableZoom = styled(Box)`
  position: relative;
  ::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
  }
  backface-visibility: hidden;
  zoom: 1;
  transform: scale(1) translate3d(0, 0, 1px);
  transition: transform 0.3s ease-in;

  & img {
    backface-visibility: hidden;
    zoom: 1;
    transform: scale(1) translate3d(0, 0, 1px);
    transition: transform 0.3s ease-in !important;
  }

  &:hover {
    backface-visibility: hidden;
    zoom: 1;
    transform: scale(0.95) translate3d(0, 0, 1px);
    transition: transform 0.3s ease-out;
    & img {
      backface-visibility: hidden;
      zoom: 1;
      transform: scale(1.15) translate3d(0, 0, 1px);
      transition: transform 0.3s ease-out !important;
    }
  }
`;

const HoverDiv = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 1px);
  zoom: 1;
  backface-visibility: hidden;
`;

const HoverContainer = posed(HoverDiv)(zoomProps);

const ChildrenContainer = posed.div({
  hidden: {
    opacity: 0,
    scale: 1.05,
    x: 0,
    y: 0,
  },
  visible: {
    scale: 1,
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      opacity: ({ visibledelay }) => ({
        delay: 400 + visibledelay,
        duration: 0,
      }),
      scale: ({ visibledelay, v }) => ({
        type: `tween`,
        delay: v ? 0 : 400 + visibledelay,
        duration: v ? 200 : 800,
        ease: v ? easings.easeSinIn : easings.easeSinOut,
      }),
    },
  },
  blur: {
    scale: 1,
    x: 0,
    y: 0,
    transition: {
      scale: {
        delay: 0,
        type: `tween`,
        duration: 200,
        ease: easings.easeSinIn,
      },
    },
  },
  hover: {
    scale: 1.08,
    x: 0,
    y: 0,
    transition: {
      scale: {
        delay: 0,
        type: `tween`,
        duration: 300,
        ease: easings.easeSinOut,
      },
    },
  },
  focus: {
    scale: 1.08,
    x: 0,
    y: 0,
    transition: {
      scale: {
        delay: 0,
        type: `tween`,
        duration: 300,
        ease: easings.easeSinOut,
      },
    },
  },
});

const Overlay = posed.figure({
  hidden: {
    y: `0`,
    x: 0,
  },
  visible: {
    y: `-120%`,
    x: 0,
    transition: ({ visibledelay, from, to }) => ({
      delay: visibledelay,
      duration: 800,
      type: "keyframes",
      values: [from, 0, to],
      times: [0, 0.5, 1],
      easings: [easings.easeSinOut, easings.easeSinIn],
    }),
  },
});

const renderZoomChildren = ({
  initialPose,
  visibledelay,
  revealColor,
  containerStyle = null,
  children,
}) => (
  <React.Fragment>
    <ChildrenContainer
      v={initialPose !== "hidden"}
      visibledelay={visibledelay}
      initialPose={initialPose}
      style={{ zIndex: 1, ...containerStyle }}
    >
      {children}
    </ChildrenContainer>
    <Overlay
      initialPose={initialPose}
      visibledelay={visibledelay}
      style={{
        position: `absolute`,
        display: `block`,
        backfaceVisibility: `hidden`,
        zoom: 1,
        top: `-25px`,
        left: `-25px`,
        maxWidth: `none`,
        maxHeight: `none`,
        backgroundColor: revealColor || `#000`,
        width: `calc(100% + 50px)`,
        height: `calc(100% + 50px)`,
        willChange: `transform`,
        zIndex: 2,
        visibility: `visible`,
      }}
    />
  </React.Fragment>
);

const PinchZoom = ({
  style,
  visibledelay = 0,
  revealColor,
  hoverable = false,
  focusable = false,
  children,
  containerStyle,
  initialPose = "hidden",
  full,
  alignRight,
  ...rest
}) => {
  return hoverable ? (
    <HoverableZoom
      initialPose={initialPose}
      style={{
        ...style,
        position: `relative`,
        backfaceVisibility: `hidden`,
        zoom: 1,
        willChange: `transform`,
        overflow: `hidden`,
      }}
      {...rest}
    >
      <HoverContainer>
        {renderZoomChildren({
          initialPose,
          visibledelay,
          revealColor,
          containerStyle,
          children,
        })}
      </HoverContainer>
    </HoverableZoom>
  ) : (
    <Zoom
      initialPose={initialPose}
      style={{
        position: `relative`,
        backfaceVisibility: `hidden`,
        willChange: `transform`,
        overflow: `hidden`,
        ...style,
      }}
      {...rest}
    >
      {renderZoomChildren({
        initialPose,
        visibledelay,
        revealColor,
        containerStyle,
        children,
      })}
    </Zoom>
  );
};

PinchZoom.propTypes = {
  style: PropTypes.object,
  containerStyle: PropTypes.object,
  visibledelay: PropTypes.number,
  revealColor: PropTypes.string,
  hoverable: PropTypes.bool,
  focusable: PropTypes.bool,
  children: PropTypes.node,
  initialPose: PropTypes.string,
};

export default PinchZoom;
