import * as easings from "d3-ease";

import { Column, Container, Row, Section } from "components/layout";
import React, { PureComponent } from "react";

import { Box } from "@rebass/grid";
import { PoseableButton } from "components/poseable";
import cssForBreakpoints from "utils/cssForBreakpoints";
import { normal } from "components/typography/sizes";
import posed from "react-pose";
import styled from "styled-components";

const StyledSection = styled(Section)`
  ${({ w, pos }) =>
    `${cssForBreakpoints("width", w)}${cssForBreakpoints("position", pos)}`}
`;

const StyledContainer = styled(Container)`
  ${({ m }) => `${cssForBreakpoints("margin", m)}`}
`;

const FilterButton = styled(
  posed(PoseableButton)({
    hoverable: true,
    focusable: true,
  })
)`
  font-family: Calibre;
  font-weight: bold;
  background: transparent;
  border: none;
  cursor: pointer;
  will-change: color;
  transition: color 0.2s ease-in;
  padding-bottom: 0;

  & sup {
    color: ${(props) => props.supcolor};
    margin-left: 4px;
  }

  :hover,
  :focus {
    color: ${(props) => props.supcolor};
    transition: color 0.3s ease-out;
  }

  :focus {
    outline: none;
    box-shadow: none;
  }
`;

const Underline = styled(
  posed.figure({
    hidden: {
      width: 0,
      x: 0,
    },
    selected: {
      width: ({ w }) => {
        return `${w - 11}px`;
      },
      x: ({ l }) => `${l}px`,
      transition: {
        type: `tween`,
        duration: 200,
        ease: easings.easeSinOut,
      },
    },
  })
)`
  position: relative;
  display: block;
  height: 3px;
  background-color: ${(props) => props.bgcolor};
`;

export default class WorkFilter extends PureComponent {
  get selectedOption() {
    return this.state.selectedOption;
  }

  constructor(props) {
    super(props);

    this.options = this.props.options;
    this.options.sort((a, b) => {
      return b.count - a.count;
    });

    this.state = {
      selectedOption: props.defaultValue || props.options[0],
      selectedIndex: -1,
      buttons: null,
    };
  }

  onButtonClick = (idx) => {
    const selectedButton =
      this.buttons && this.buttons[idx] ? this.buttons[idx] : null;
    const containerBounds = this.container.getBoundingClientRect();
    const bounds = selectedButton
      ? selectedButton.getBoundingClientRect()
      : null;
    const underline = {
      left: bounds ? Math.round(bounds.left - containerBounds.left) : 0,
      width: bounds ? Math.round(bounds.width) : 0,
    };

    this.setState(
      {
        selectedOption: this.options[idx].value,
        selectedIndex: idx,
        selectedButton,
        underline,
      },
      () => {
        this.props.onChange(this.state.selectedOption);
      }
    );
  };

  componentDidMount() {
    const { theme } = this.props;
    this.buttons = [];

    const buttons = (
      <React.Fragment>
        {this.options.map((opt, idx) => {
          return (
            <FilterButton
              ref={(r) => (this.buttons[idx] = r)}
              px={0}
              mb={0}
              key={`workfilter-option-${idx}`}
              color={theme.colors.black}
              fontSize={normal}
              supcolor={theme.colors.blue}
              mr={[10, 15, 20]}
              onClick={() => this.onButtonClick(idx)}
            >
              <span>{opt.value}</span>
              <sup>{opt.count}</sup>
            </FilterButton>
          );
        })}
      </React.Fragment>
    );

    this.setState({ buttons: buttons }, () => {
      const selectedButton =
        this.buttons && this.buttons[0] ? this.buttons[0] : null;
      const containerBounds = this.container.getBoundingClientRect();

      const bounds = selectedButton
        ? selectedButton.getBoundingClientRect()
        : null;
      const underline = {
        left: bounds ? Math.round(bounds.left - containerBounds.left) : 0,
        width: bounds ? Math.round(bounds.width) : 0,
      };
      this.setState({
        selectedIndex: 0,
        selectedButton,
        underline,
      });
    });
  }

  render() {
    const { theme } = this.props;
    const { underline, selectedIndex } = this.state;

    return (
      <StyledSection
        py={0}
        pb={0}
        pt={0}
        w={[`100%`, `100%`, `auto`]}
        pos={[
          "relative !important",
          "relative !important",
          "absolute !important",
        ]}
        is="aside"
        style={{
          display: `block`,
          bottom: 10,
          right: 0,
          left: `auto`,
        }}
      >
        <StyledContainer
          m={[
            "20px 0 0 !important",
            "20px 0 0!important",
            "0px auto !important",
          ]}
        >
          <Row>
            <Column width={[1, 1, 5 / 12]} ml={[0, 0, `${(7 / 12) * 100}%`]}>
              <Box width={1} ref={(r) => (this.container = r)} py={0}>
                {this.state.buttons}
              </Box>
              <Box width={1} py={0}>
                <Underline
                  bgcolor={theme.headingColor}
                  w={underline ? underline.width : 0}
                  l={underline ? underline.left : 0}
                  initialPose={"hidden"}
                  poseKey={selectedIndex}
                  pose={"selected"}
                />
              </Box>
            </Column>
          </Row>
        </StyledContainer>
      </StyledSection>
    );
  }
}
